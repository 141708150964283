import React from "react";
import mic from "../assets/mic.png";
import pic1 from "../assets/newpodcasts/sibling-rivalry@2x 2.png";
import pic2 from "../assets/newpodcasts/sibling-rivalry@2x 3.png";
import pic3 from "../assets/newpodcasts/sibling-rivalry@2x 4.png";
import pic4 from "../assets/newpodcasts/sibling-rivalry@2x 5.png";
import pic5 from "../assets/newpodcasts/sibling-rivalry@2x 6.png";
import pic6 from "../assets/newpodcasts/sibling-rivalry@2x 7.png";
import pic7 from "../assets/newpodcasts/sibling-rivalry@2x 8.png";
import vector from "../assets/svgs/Vector.svg";
import vector1 from "../assets/svgs/Vector-1.svg";
import ScrollAnimation from "react-animate-on-scroll";
//import podbox from '../assets/podbox.png';
//import {SiSpotify} from 'react-icons/si'

const LPodcasts = () => {
  const audience = [
    "Jeremy",
    "Bobby",
    "Shawn",
    "Maria",
    "Charles",
    "Dennis",
    "Doris",
    "Carolyn",
  ];

  const host = [
    {
      name: "Eric Zhu",
      bg: "#564A4A",
    },
    {
      name: "Kevin",
      bg: "#D9BFCD",
    },
    {
      name: "Edmund",
      bg: "#DEDEDE",
    },
  ];
  return (
    <div className="mt-[20rem] w-full">
      <h2 className="w-4/5 mx-auto font-normal text-[2rem] lg:hidden">
        Our Latest Episodes
      </h2>
      <ScrollAnimation animateIn="fadeInDown" animateOnce="true">
        <h2 className="w-max mx-auto font-normal text-[2rem] hidden lg:block">
          Get inspired with our latest episodes out there
        </h2>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp">
        <div className="whitespace-nowrap space-x-4 w-4/5 lg:w-[90%] mt-[2rem] lg:mt-[5rem] mx-auto">
          <img alt="img" src={pic1} className="w-[45%] max-w-[171px] inline" />
          <img alt="img" src={pic2} className="w-[45%] max-w-[171px] inline" />
          <img alt="img" src={pic3} className="w-[45%] max-w-[171px] inline" />
          <img alt="img" src={pic4} className="w-[45%] max-w-[171px] inline" />
          <img alt="img" src={pic5} className="w-[45%] max-w-[171px] inline" />
          <img alt="img" src={pic6} className="w-[45%] max-w-[171px] inline" />
          <img alt="img" src={pic7} className="w-[45%] max-w-[171px] inline" />
          <img alt="img" src={pic2} className="w-[45%] max-w-[171px] inline" />
          <img alt="img" src={pic3} className="w-[45%] max-w-[171px] inline" />
        </div>
      </ScrollAnimation>
      <div className="lg:hidden mt-[5rem] flex justify-center">
        <img alt="img" src={vector} className="w-full" />
        <img alt="img" src={vector1} className="w-full" />
      </div>
      <div className="flex flex-col lg:flex-row w-4/5 mx-auto lg:justify-between lg:items-center lg:mt-[15rem]">
        <h3 className="w-[90%] lg:hidden mx-auto font-semibold text-[1.7rem]">
          Listen us live on Discord
        </h3>
        <ScrollAnimation animateIn="fadeInLeft" animateOnce="true">
          <div className="hidden lg:flex flex-col text-[2.5rem]">
            <p>Get the chance</p>
            <p>to listen us live</p>
            <p>
              only on{" "}
              <span className="text-[#0075FF] underline cursor-pointer">
                discord
              </span>
            </p>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInRight" animateOnce="true">
          <div className="lg:mt-0 mt-8 w-[90%] mx-auto lg:mx-[0rem] lg:max-w-[600px]  max-w-[451.8px]">
            <div className="bg-[#212121]   mx-auto rounded-md p-[0.5rem]">
              {/* podbox */}
              <h3 className="mb-2">
                Short talk with a great founder Kevin Ross
              </h3>
              <div className="flex justify-start w-[90%] mx-auto space-x-2">
                {host.map(({ bg, name }) => {
                  return (
                    <div
                      className={` py-2 rounded-md w-full max-w-[139px]`}
                      style={{ backgroundColor: `${bg}` }}
                    >
                      <img
                        alt="img"
                        className="w-[3rem] mx-auto h-[3rem] rounded-full border border-black"
                      />
                      <div className=" w-[95%] mx-auto mt-4 text-center rounded-md bg-[#BD9FAF] p-2">
                        {name}
                      </div>
                    </div>
                  );
                })}
              </div>
              <hr className="w-[90%] mx-auto my-4" />
              <p className="text-[#929292] text-[0.9rem] w-[90%] mx-auto">
                in audience - {audience.length}
              </p>
              <div className="w-[90%] mx-auto justify-center flex flex-wrap">
                {audience.map((name) => {
                  return (
                    <div className="mr-4 p-2 mb-2">
                      <img
                        alt="img"
                        className="w-[3rem] mb-2 mx-auto h-[3rem]  rounded-full border "
                      />
                      <p>{name}</p>
                    </div>
                  );
                })}
              </div>
              <div className="lg:hidden flex justify-between bg-gray-200 bg-opacity-10 rounded-lg p-4 items-center ">
                <h3 className="text-[1.5rem]">🔥 Fireside</h3>
                <button className="bg-[#0075FF] text-[1.3rem] rounded-md py-1 px-[1.8rem]">
                  Join
                </button>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
      <div>
        <div className="hidden border justify-around lg:flex mt-[6rem]">
          <div className="border flex flex-col justify-center font-bold text-3xl ">
            <h3>Catch Our Latest Episodes On </h3>
            {/* Color SVGS  */}
            <div>
              <svg
                width="108"
                height="108"
                viewBox="0 0 108 108"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M53.9997 0C24.1771 0 0 24.177 0 54.0003C0 83.8249 24.1771 108 53.9997 108C83.8255 108 108 83.8249 108 54.0003C108 24.179 83.8255 0.00257935 53.999 0.00257935L53.9997 0ZM78.7636 77.884C77.7963 79.4703 75.72 79.9733 74.1337 78.9995C61.4552 71.255 45.4944 69.501 26.6977 73.7957C24.8864 74.2084 23.0809 73.0734 22.6682 71.2614C22.2536 69.4494 23.384 67.6439 25.1998 67.2312C45.7698 62.5296 63.4141 64.5551 77.648 73.254C79.2343 74.2277 79.7373 76.2977 78.7636 77.884ZM85.373 63.1783C84.1543 65.1612 81.5621 65.7867 79.5825 64.568C65.0675 55.644 42.9416 53.0601 25.7731 58.2718C23.5465 58.9443 21.1948 57.6895 20.519 55.4667C19.8484 53.2401 21.1039 50.8928 23.3266 50.2157C42.9377 44.2651 67.3179 47.1476 83.9867 57.3909C85.9663 58.6096 86.5918 61.2019 85.373 63.179V63.1783ZM85.9405 47.8672C68.5366 37.5298 39.8225 36.5793 23.206 41.6226C20.5377 42.4319 17.716 40.9255 16.9073 38.2572C16.0987 35.5875 17.6038 32.7676 20.274 31.9564C39.3486 26.1657 71.0579 27.2845 91.0952 39.1799C93.5004 40.6044 94.2871 43.7041 92.8621 46.101C91.4434 48.5011 88.3354 49.2923 85.9431 47.8672H85.9405Z"
                  fill="#1ED760"
                />
              </svg>
            </div>
          </div>
          <img alt="img" src={mic} className="object-fit" />
        </div>
      </div>
      <div className="font-normal w-[90%] flex justify-between mx-auto my-[4rem]">
        <h3 className="cursor-pointer">2021 © Founders Mafia</h3>
        <h3 className="cursor-pointer hidden lg:inline-flex">
          helo@foundersmafia.org
        </h3>
        <h3>Privacy Policy</h3>
      </div>
    </div>
  );
};

export default LPodcasts;
